import * as React from "react";
import { IconProps } from "..";

const Inverted = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "inverted", titleId = "inverted", ...props }, svgRef) => {
    return (
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 56.25 56.25"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path d="M0 0v56.25h56.25A56.24 56.24 0 010 0z" fill="currentColor" />
      </svg>
    );
  }
);
export default Inverted;
